<template>
  <div class="index">
    <img :src="$getImgUrl('assets/bg-2.png')" alt="" />
    <div class="box">
      <img :src="$getImgUrl('assets/360.png')" alt="" @click="$setRead(2, 1)" />
    </div>
    <img class="title-img" :src="$getImgUrl('assets/title2-1.png')" alt="" />
    <div class="box">
      <img :src="$getImgUrl('assets/video.png')" alt="" />
      <div class="video">
        <m-video-player
          src="https://zk.ohedu.cn/upload/video/filename/16740/SH/master.m3u8"
          name="2"
          @play="$setRead(2, 2)"
        >
        </m-video-player>
      </div>
    </div>
    <img class="title-img" src="@/assets/title2-2.png" alt="" />
    <div @click="$setRead(2, 3)" class="news">
      <div
        v-for="n in article"
        :key="n.id"
        @click="$router.push('/article?id=' + n.id)"
      >
        <img src="@/assets/box-4.png" alt="" />
        <img
          class="news-img"
          :src="$getImgUrl(`assets/2-3/1${n.title}.png`)"
          alt=""
        />
        <p>{{ n.title }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import { article } from "./list";
export default {
  name: "App",
  components: {},
  data() {
    return {
      article,
    };
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {},
  watch: {},
};
</script>
  
<style lang="less" scoped>
.news {
  margin: 12px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  > div {
    width: 170px;

    margin-bottom: 10px;
    text-align: center;
    position: relative;
    > img {
      width: 170px;
      font-size: 15px;
    }
    .news-img {
      width: 160px;
      position: absolute;
      top: 5px;
      left: 50%;
      height: 96px;
      transform: translateX(-50%);
      border-radius: 5px;
      object-fit: contain;
    }
    > p {
      margin-top: 4px;
    }
  }
}
</style>