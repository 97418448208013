export const farm = [{
    id: 1,
    title: '篓',
    tltleE: 'Basket',
    content: '篓是用竹或荆条等编成的盛东西的器具，呈圆筒状。它利用悬挂点的力学原理，将重物悬挂在篓子上。抬篓子的把手有一定的角度，使抬篓子的重量更加均匀，从而容易抬起物品。'
},
{
    id: 2,
    title: '菜油灯',
    tltleE: 'Vegetable oil lamp',
    content: '菜油灯是使用菜籽油为燃料的油灯，主要的用途是照明。菜油灯主要由灯盏、灯芯组成，如同现在的灯具一样。魏晋之后，菜油使用非常普遍，在电灯普及之前，菜油灯一直是中国人最常用的灯具。'
},
{
    id: 3,
    title: '火钳',
    tltleE: 'Forge tongs',
    content: '火钳为铁制夹取柴火的工具，由两部分组成，两部分均有手柄、钳肩、钳臂。火钳多见于农村家庭日常生活做饭及冬天取暖时添加柴火时使用，也见于夹取煤炭或蜂窝煤使用，现大街小巷保洁员也有拿它拾取地面垃圾使用。'
},
{
    id: 4,
    title: '谷风机',
    tltleE: 'Grain blower',
    content: '谷风机是一种用来去除谷子中的杂质、瘪粒、碎秆等木制的传统农具，在古时候是名副其实的“高科技"。谷风机的构造包括梯形的装料斗，一叶轮，铁做的摇柄。人们手摇转动风叶产生风力用来吹谷物，转动速度快产生的风也越大，效果也好。'
},
{
    id: 5,
    title: '碾子',
    tltleE: 'Roller',
    content: '碾子是用人力或畜力把高粱、谷子、稻子等谷物脱壳或把米碾碎成面粉的石制工具。碾子由碾台、碾盘、碾滚和碾架等组成，通过碾滚子在碾盘上的滚动达到碾轧加工粮食的目的。'
}, {
    id: 6,
    title: '扁担',
    tltleE: 'Carrying pole',
    content: '扁担是一种扁圆、长条形的竹木用具，放在肩上挑东西或抬东西。扁担有用木制的，也有用竹做的，其外形一般都是简朴自然：直挺挺的，酷似一个简简单单的“一"字。扁担是生产生活中的用具之一，尤其是山区交通不便的地方，它是搬运货物的便捷有效的工具！'
}]
export const article = [
    {
        id: 1,
        title: "留声机", content: "【介绍文字】留声机是一种放音装置，可以简单地分为唱盘、大喇叭、唱臂、唱头。留声机是由美国发明家爱迪生于1877年发明的，它能比较简易地大量复制，放映时间也比大多数筒形录音介质长。学生用纸板等素材制作出留声机模型，比较完整地还原了它的最初形态。"
    },
    {
        id: 2,
        title: "纸制扁担", content: "【介绍文字】扁担是生产生活的用具之一，形状为扁圆、长条形，一般用木或竹等材料制作，放在肩上挑东西或抬东西，两端挂篓用来盛放物品。这是学生用纸板和红绳等材料制作的简易扁担模型。"
    },
    {
        id: 3,
        title: "3d扁担", content: "【介绍文字】3d扁担是先在3D one建模软件中设计出外观模型，再用3d打印机打印出来的立体造型扁担，其有一定的科技含量。这种制作的方式深受学生欢迎和喜爱。这是一名学生制作的“双喜临门”3d扁担，寓意古代婚嫁幸福美满。"
    },
    {
        id: 4, isTwo: true,
        title: "“向宽而行”团扇", content: "【介绍文字】团扇是用半生半熟宣纸和楠竹等材料制成的一种扇子，其款式一般有月圆、飞仙、美人、海棠、八瓣、六瓣等等，可以用来扇风纳凉，书画赠礼等。图中展示的是学生制作的“向宽而行”团扇，其款式为皇冠，正面所见为校园的艺术字形态校训“向宽而行”，背面呈现的是精美的书法作品。"
    },
    {
        id: 5, isTwo: true,
        title: "“文美行雅”团扇", content: "【介绍文字】这是一款加厚款的团扇，由学生创作，取名为“文美行雅”。“文美行雅”是校园的校风，在这个作品中有体现。此款团扇其正面为美观雅致的校门，背面为校园的吉祥物阿宽和小榉，他们敞开双臂欢迎着同学们，象征着满满的热情和无穷的活力。"
    },
    {
        id: 6,
        title: "葫芦瓢脸谱画", content: "【介绍文字】葫芦瓢是用葫芦干壳做成的勺，在我国古代是民间常用的一种舀水工具。葫芦瓢可以舀水，也可以盛米、盛酒、盛药、盛粟，酸的甜的苦的辣的都可盛装。这是学生绘制的葫芦瓢脸谱，含有辟邪的民俗意义。"
    },
    {
        id: 7,
        title: "葫芦瓢俄罗斯套娃", content: "【介绍文字】这是学生在葫芦瓢上绘制的俄罗斯套娃。俄罗斯套娃是俄罗斯特产的木制玩具，一般由多个一样图案的空心木娃娃一个套一个组成。学生根据葫芦瓢的外形进行联想，发现葫芦瓢的外形与俄罗斯套娃有相似之处，于是绘制了这件作品。"
    },
    {
        id: 8,
        title: "葫芦瓢叮当猫", content: "【介绍文字】叮当猫是孩子们喜闻乐见的卡通形象。他有圆圆的眼睛，圆滚滚的大脑袋，圆乎乎的身体，以及他脖子上圆形的黄色铃铛。叮当猫的造型特点以圆形为主。学生根据叮当猫和葫芦瓢造型的共同点，设计绘制了这款富有创意的葫芦瓢。"
    },
    {
        id: 9,
        title: "轻黏土摆件", content: "【介绍文字】这是学生用废旧塑料瓶以及超轻黏土制作的小丑摆件。作品以圆形为主要的造型元素，呈现了小丑的卷发、眼睛、鼻子、嘴巴以及衣领，以简洁、夸张的手法高度概括了小丑的造型特点。作品色彩明快，独具风格。"
    },
    {
        id: 10,
        title: "轻黏土作品", content: "【介绍文字】超轻黏土是学生非常喜爱的一种材料，它色彩饱满，可塑性强，极具表现力。这是学生用废旧塑料瓶以及超轻黏土制作的人物摆件，内置塑料瓶，外面用轻黏土包裹。学生用椭圆形、圆形等基本型概括人物造型，表现出人物独特的造型及表情。"
    },
    {
        id: 11,
        title: "纸制钟表", content: "【介绍文字】这是学生设计制作的一款电子时钟。表盘由学生绘画并制作。学生从汽车中控仪表盘获得灵感，在表盘上绘制了汽车底部仰视图，图中标有胎压等汽车情况信息，右侧柱状图表示汽油剩余量等。指针走动就像汽车在行进过程中，仪表盘上的指针跟着旋转的样子。整个作品简洁有力，搭配的颜色也与汽车给人的低调、酷炫的感觉十分吻合。"
    },
    {
        id: 12,
        title: "小黄人", content: "【介绍文字】这是学生利用废旧玻璃瓶及彩色麻绳绕制而成的一个桌面装饰摆件。学生将小黄人的造型特点很好地融入到了瓶子身上，抓住了小黄人的色彩特征、大眼睛造型特征、蝴蝶结等，蓝黄搭配，色彩对比强烈。"
    },
    {
        id: 13,
        title: "孙悟空", content: "【介绍文字】这是学生利用废旧玻璃酒瓶及彩色麻绳绕制而成的一个桌面装饰摆件。学生将孙悟空的形象进行简化、概括，用几个简单的造型元素让人明确了孙悟空的形象。红黄蓝原色的搭配，对比强烈，制作也十分精美。"
    },
    {
        id: 14,
        title: "书签1", content: "【介绍文字】这是学生绘制的茶文化书签。作品采取了对角线式的构图，表现出茶的流动性。色彩沉稳，富有美感。"
    },
    {
        id: 15,
        title: "书签2", content: "【介绍文字】这是学生绘制的茶文化书签。作品采取了居中式构图，用扇子、茶具等简单的内容勾画出一张漂亮的书签。作品以绿色调为主，色彩和谐，画面饱满。"
    },
    {
        id: 16,
        title: "书签3", content: "【介绍文字】这是学生绘制的茶文化书签。作品采取了对角线式的构图，表现了茶具、古籍、荷花、池塘等内容。画面简洁、工整，留白适当。"
    },
    {
        id: 17,
        title: "木板画1", content: "【介绍文字】这是学生在木片上绘制的丙烯画。画面表现了春天两只鸳鸯在水边嬉戏。柳条低垂，鲜花盛开，这是春天的场景。整个画面色彩和谐，高级，四周绘以花窗造型的边框，使得画面更加精致工整。"
    },
    {
        id: 18,
        title: "木板画2", content: "【介绍文字】这是学生在木片上绘制的丙烯画。画面表现的是秋日的午后，有两人结伴泛舟于湖面之上，大雁结队南飞，落日余晖照亮了整个湖面。画面前景、中景、远景处理得当，充满了空间感。"
    },
    {
        id: 19,
        title: "木板画3", content: "【介绍文字】这是学生在木片上绘制的丙烯画。画面表现的是山谷的湖面上，有二人结伴划船，湖面、白云、蓝天、青山、绿树、亭子互相映照，春意盎然。近处湖水深，远处湖水浅，让人有无限的对空间的想象。"
    },
    {
        id: 20,
        title: "木板画4", content: "【介绍文字】这是学生在木片上绘制的丙烯画。画面表现的是一只公鸡在院子里捉虫吃。公鸡低着头，翘着尾巴，表现出它啄虫的动态。红黄蓝三原色，表现出公鸡漂亮的羽毛。近景从画面的高处，伸出两朵菊花，丰富了画面。"
    },
]